import { Expose, Type } from 'class-transformer'
import { OrderShipping } from './OrderShipping'
import { OrderItem } from './OrderItem'
import { IFarm } from '../../interfaces/IFarm'
import { Professional } from '../Professional'
import { OrderPayment } from './OrderPayment'
import { IHarvest } from '../../interfaces/IHarvest'
import { IProfessional } from '../../interfaces/IProfessional'

export class Order {
  @Expose()
  id!: number

  @Expose({ name: 'codigoIdentificacao' })
  readableIdentifier!: string

  @Expose({ name: 'dataGeracao' })
  @Type(() => Date)
  generatedAt!: Date

  @Expose({ name: 'dataValidade' })
  @Type(() => Date)
  validUntil!: Date

  @Expose({ name: 'vendedorId' })
  professionalId!: number

  @Expose({ name: 'vendedor' })
  @Type(() => Professional)
  professional?: Professional

  @Expose({ name: 'cadastradorId' })
  registerId!: number

  @Expose({ name: 'status' })
  status!: OrderStatus

  @Expose({ name: 'safraId' })
  harvestId!: string

  @Expose({ name: 'safra' })
  harvest?: IHarvest

  @Expose({ name: 'fazendaId' })
  farmId!: number

  @Expose({ name: 'fazenda' })
  farm?: IFarm

  @Expose({ name: 'usarCnpjFazenda' })
  farmUseCnpj?: boolean

  @Expose({ name: 'isDescontoExcedido' })
  isDiscountExceeded!: boolean

  @Expose({ name: 'isPendenteAnaliseCredito' })
  isWaitingCreditAnalysis!: boolean

  @Expose({ name: 'isPendenteAnaliseTipoVenda' })
  isWaitingSalesTypeAnalysis!: boolean

  @Expose({ name: 'isPendenteAnalisePreco' })
  isWaitingPriceAnalysis!: boolean

  @Expose({ name: 'apenasDiretorAprovarCredito' })
  onlyDiretorApproveCredit!: boolean

  @Expose({ name: 'apenasDiretorAprovarPreco' })
  onlyDiretorApprovePrice!: boolean

  @Expose({ name: 'percentualDescontoPermitido' })
  allowedDiscount!: number

  @Expose({ name: 'quantidadeTotalSacas' })
  totalSacks!: number

  @Expose({ name: 'valorTotalGermoplasma' })
  totalSeed!: number

  @Expose({ name: 'valorTotalTecnologia' })
  totalRoyalties!: number

  @Expose({ name: 'valorTotalTratamento' })
  totalTreatment!: number

  @Expose({ name: 'valorTotalFrete' })
  totalShipping!: number

  @Expose({ name: 'valorTotalGeral' })
  total!: number

  @Expose({ name: 'tipoVenda' })
  salesType!: string

  @Expose({ name: 'valorSaca' })
  bagValue?: number

  @Expose({ name: 'valorTotalGeralEmSacas' })
  totalBags?: number

  @Expose({ name: 'tipoCategoriaCultivar' })
  categoryCultivar!: TipoCategoriaCultivar

  @Expose({ name: 'observacao' })
  notes!: string

  @Expose({ name: 'envelopePedidoId' })
  envelopeOrderId?: string

  @Expose({ name: 'envelopeContratoId' })
  envelopeContractId?: string

  @Expose({ name: 'envelopeAditivoId' })
  envelopeAdditiveId?: string

  @Expose({ name: 'frete' })
  @Type(() => OrderShipping)
  shipping!: OrderShipping

  @Expose({ name: 'pagamento' })
  @Type(() => OrderPayment)
  payment!: OrderPayment

  @Expose({ name: 'itens' })
  @Type(() => OrderItem)
  items!: OrderItem[]

  @Expose({ name: 'frontExtra' })
  orderSimulationPayload!: string

  @Expose({ name: 'historicoStatus' })
  history!: IOrderHistory[]

  @Expose({ name: 'descontoTratamentoId' })
  treatmentDiscountId?: number

  @Expose({ name: 'percentualDescontoTratamento' })
  treatmentDiscountPercent?: number

  @Expose({ name: 'contratoInfos' })
  contractInfo!: IOrderContractInfo[]

  @Expose({ name: 'pedidoReferenciaId' })
  pedidoReferenciaId?: number

  @Expose()
  statusLiberacaoTratamento?: string

  @Expose()
  aprovarLiberacaoTSIPedido?: boolean

  @Expose()
  aditivoContratoInfo?: IOrderAditivoInfo
}

export enum OrderStatus {
  SimulationInProgress = 'Simulação em Andamento',
  SimulationInPriceAnalysis = 'Simulação em Análise de Preço',
  SimulationInTypeAnalysis = 'Simulação em Análise de Tipo de Venda',
  SimulationCanceled = 'Simulação Cancelada',
  SimulationDenied = 'Simulação Negada pelo Gerente',
  SimulationApproved = 'Simulação Aprovada',
  SimulationExpired = 'Simulação Vencida',
  ProposalInProgress = 'Proposta em Andamento',
  ProposalDeniedByClient = 'Proposta Recusada pelo Cliente',
  ProposalDenied = 'Proposta Cancelada',
  ProposalExpired = 'Proposta Vencida',
  InProgress = 'Pedido em Andamento',
  InCreditAnalysis = 'Pedido em Análise de Crédito',
  InAnalysis = 'Pedido em Análise para Geração de Contrato',
  Expired = 'Pedido Vencido',
  Canceled = 'Pedido Cancelado',
  Finished = 'Pedido Finalizado'
}

export enum TipoCategoriaCultivar {
  DefaultSale = 'Venda Padrão',
  ReadySeed95 = 'Semente Pronta 95%',
  SafetyStand = 'Stand Seguro'
}

export interface IOrderHistory {
  id: number
  data: string
  status: OrderStatus
  observacao?: string
  geradoAutomaticamente: boolean
  profissionalRealizadorId?: number
  profissionalRealizador?: IProfessional
}

export interface IOrderContractInfo {
  id: number
  pedidoId: number
  numeroContrato: string
  dataGeracao: string
  germinacaoMinima: string
  profissionalGeradorId: number
  isAtivo: boolean
  justificativaCancelamento?: string
  isAprovado: boolean
  isAvaliado: boolean
  dataAvaliacao?: string
  profissionalAvaliadorId?: number
  profissionalAvaliador?: IProfessional
  profissionalCancelador?: IProfessional
  isContratoPendenciaPrecoTecnologia: boolean
}

export interface IOrderAditivoInfo {
  id: number
  pedidoId: number
  numeroAditivo: string
  dataGeracao: string
  numeroContratoReferencia: string
  profissionalGeradorId: number
  profissionalGerador: IProfessional
}
