import Axios from 'axios'
import { plainToClass } from 'class-transformer'
import moment from 'moment'
import { IAccessModel } from '../components/SelectAccessModel'
import { ICity } from '../components/SelectCities'
import { IClientType } from '../components/SelectClientTypes'
import { IObtentora } from '../components/SelectObtentoras'
import { IState } from '../components/SelectStates'
import { IAgent } from '../interfaces/IAgent'
import { IClient, IClientData } from '../interfaces/IClient'
import { IContact, IContactData } from '../interfaces/IContact'
import { ICultive, ICultiveWithVolume } from '../interfaces/ICultive'
import { IFarm, IFarmData } from '../interfaces/IFarm'
import {
  IFreightPrice,
  IFreightPriceFormData
} from '../interfaces/IFreightPrice'
import { IGermoPrice, IGermoPriceFormData } from '../interfaces/IGermoPrice'
import {
  ICertificadora,
  IHarvest,
  IHarvestSubmitPayload,
  IMultiplicadora
} from '../interfaces/IHarvest'
import { IForecast } from '../interfaces/IForecast'
import { IMaxDiscountFormData } from '../interfaces/IMaxDiscount'
import { IPaginatedResponse } from '../interfaces/IPaginatedResponse'
import { IPmsFormData } from '../interfaces/IPms'
import { IPopulation, IPopulationFormData } from '../interfaces/IPopulation'
import {
  IQuota,
  IQuotaFormData,
  IQuotaRegionalFormData
} from '../interfaces/IQuota'
import {
  IRecomendationRate,
  IRecomendationRateData
} from '../interfaces/IRecomendationRate'
import { IRtvQuota } from '../interfaces/IRtvQuota'
import {
  ISeasonalDiscount,
  ISeasonalDiscountPayload
} from '../interfaces/ISeasonalDiscount'
import { ISubQuotaFormData } from '../interfaces/ISubQuota'
import { ITechnology } from '../interfaces/ITechnology'
import { ITechPrice, ITechPricePayload } from '../interfaces/ITechPrice'
import { IWalletSubmitPayload, IWallet } from '../interfaces/IWallet'
import { AvailableSaleVolume } from '../models/AvailableSaleVolume'
import { Professional } from '../models/Professional'
import { createApiError } from './createApiError'
import { serverClient } from './serverClient'
import { IQuotaHistory } from '../interfaces/IQuotaHistory'
import { IPeriod, IPeriodSubmitPayload } from '../interfaces/IPeriod'
import { IBarterTax } from '../interfaces/IBarterTax'
import { ICustomerShare, IWalletShare } from '../interfaces/ICustomerShare'
/**
 * ----------------------------------------------------------------------------
 * Autenticação
 * ----------------------------------------------------------------------------
 */

export interface ILoginResponse {
  token: string
  refreshToken: string
}

export async function tryLogin(username: string, password: string) {
  try {
    // eslint-disable-next-line @typescript-eslint/camelcase
    const requestBody = { username, password, grantType: 'password' }

    const response = await Axios.post<ILoginResponse>(
      '/Usuario/login',
      requestBody,
      { baseURL: process.env.REACT_APP_BASE_URL }
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryRefreshToken(token: string) {
  try {
    // eslint-disable-next-line @typescript-eslint/camelcase
    const requestBody = { refreshToken: token, grantType: 'refreshToken' }

    const response = await Axios.post<ILoginResponse>(
      '/Usuario/login',
      requestBody,
      { baseURL: process.env.REACT_APP_BASE_URL }
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * Endereços
 * ----------------------------------------------------------------------------
 */

export async function tryGetStates(query: string = '') {
  try {
    const params = {
      pageSize: 30,
      orderBy: ['Descricao'],
      criterio: query
    }

    const response = await serverClient.get<IPaginatedResponse<IState>>(
      '/Estado',
      { params }
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetState(id: string) {
  try {
    const response = await serverClient.get<IState>(`/Estado/${id}`)

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetCities(stateId: string, query: string = '') {
  try {
    const params = {
      pageSize: 30,
      orderBy: ['Descricao'],
      descricao: query,
      estadoId: stateId
    }

    const response = await serverClient.get<IPaginatedResponse<ICity>>(
      '/Cidade',
      { params }
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetCity(id: string) {
  try {
    const response = await serverClient.get<ICity>(`/Cidade/${id}`)

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * Relações de cliente
 * ----------------------------------------------------------------------------
 */

export async function tryGetClientType(query: string = '') {
  try {
    const response = await serverClient.get<IPaginatedResponse<IClientType>>(
      `/TipoCliente?descricao=${query}`
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetAccessModel(query?: string) {
  try {
    const response = await serverClient.get<IPaginatedResponse<IAccessModel>>(
      `/ModeloAcesso?descricao=${query ? query : ''}`
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * Clientes
 * ----------------------------------------------------------------------------
 */

export async function tryGetClient(id: number) {
  try {
    const params = {
      include: [
        'Uploads',
        'Cidade.Estado',
        'ModeloAcesso',
        'TipoCliente',
        'DescontoTratamentos',
        'DescontoTratamentos.TipoDescontoTratamento',
        'Carteiras.Carteira'
      ]
    }

    const response = await serverClient.get<IClient>(`/Cliente/${id}`, {
      params
    })

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function associateUploadsWithClient(
  clientId: number,
  uploadsIds: string[]
) {
  try {
    await serverClient.put<IClient>(`/Cliente/${clientId}/uploads`, {
      uploadsIds
    })
  } catch (e) {
    throw createApiError(e)
  }
}

interface IGetClientsOptions {
  page?: number
  pageSize?: number
  nome?: string
  cpfCnpj?: string
  orderBy?: string
  carteiraProfissionalId?: number
  appUsuarioId?: string
}

export async function tryGetClients(options: IGetClientsOptions = {}) {
  try {
    const config = {
      params: {
        ...options,
        include: [
          'Uploads',
          'Cidade.Estado',
          'Cidade.Estado',
          'ModeloAcesso',
          'TipoCliente',
          'DescontoTratamentos.TipoDescontoTratamento',
          'Carteiras.Carteira'
        ]
      }
    }

    const response = await serverClient.get<IPaginatedResponse<IClient>>(
      '/Cliente',
      config
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryPostClient(data: IClientData) {
  try {
    const response = await serverClient.post<IClient>('/Cliente', data)

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryPutClient(clientId: number, data: IClientData) {
  try {
    const response = await serverClient.put<IClient>(
      `/Cliente/${clientId}`,
      data
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryDeleteClient(clientId: number) {
  try {
    await serverClient.delete(`/Cliente/${clientId}`)
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * Contatos
 * ----------------------------------------------------------------------------
 */

export async function tryGetContacts(
  clientId: number,
  page: number,
  pageSize?: number
) {
  try {
    const config = {
      params: {
        clienteId: clientId,
        page,
        pageSize
      }
    }

    const response = await serverClient.get<IPaginatedResponse<IContact>>(
      `/Contato`,
      config
    )
    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryPostContact(data: IContactData) {
  try {
    const response = await serverClient.post<IContact>(`/Contato`, data)

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryPutContact(contactId: number, data: IContactData) {
  try {
    const response = await serverClient.put<IContact>(
      `/Contato/${contactId}`,
      data
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryDeleteContact(contactId: number) {
  try {
    await serverClient.delete(`/Contato/${contactId}`)
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * Fazendas
 * ----------------------------------------------------------------------------
 */

export async function getFarmsFromClient(
  clientId: number,
  page?: number,
  pageSize?: number
) {
  try {
    const config = {
      params: {
        clienteId: clientId,
        page,
        pageSize: pageSize || 99,
        include: ['Cidade.Estado', 'FazendaSafras']
      }
    }

    const response = await serverClient.get<IPaginatedResponse<IFarm>>(
      `/Fazenda`,
      config
    )
    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function getSingleFarm(id: number) {
  try {
    const config = {
      params: {
        include: ['Cidade.Estado']
      }
    }

    const response = await serverClient.get<IFarm>(`/Fazenda/${id}`, config)
    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryPostFarm(data: IFarmData) {
  try {
    const response = await serverClient.post<IFarm>(`/Fazenda`, data)

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryPutFarm(farmId: number, data: IFarmData) {
  try {
    const response = await serverClient.put<IFarm>(`/Fazenda/${farmId}`, data)

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryDeleteFarm(farmId: number) {
  try {
    await serverClient.delete(`/Fazenda/${farmId}`)
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * Safras
 * ----------------------------------------------------------------------------
 */

export async function tryGetHarvests() {
  try {
    const params = {
      orderBy: 'AnoColheita'
    }

    const response = await serverClient.get<IPaginatedResponse<IHarvest>>(
      `/Safra`,
      { params }
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetHarvest(id: string) {
  try {
    const response = await serverClient.get<IHarvest>(`/Safra/${id}`)

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function fetchCurrentHarvest() {
  try {
    const response = await serverClient.get<IHarvest>(`/Safra/atual`)

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryPutHarvest(data: IHarvestSubmitPayload) {
  try {
    if (data.id) await serverClient.put<IHarvest>(`/Safra/${data.id}`, data)
    else await serverClient.post<IHarvest>(`/Safra`, data)
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * Obentoras
 * ----------------------------------------------------------------------------
 */

export async function tryGetObtentoras(query?: string) {
  try {
    const response = await serverClient.get<IPaginatedResponse<IObtentora>>(
      `/Obtentora?descricao=${query ? query : ''}`
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * Tecnologias
 * ----------------------------------------------------------------------------
 */

export async function tryGetTechnologies(
  page: number,
  pageSize: number,
  orderBy: string,
  query?: string
) {
  try {
    const params = {
      page,
      pageSize,
      orderBy,
      descricao: query
    }

    const response = await serverClient.get<IPaginatedResponse<ITechnology>>(
      '/Tecnologia',
      { params }
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetTechnology(id: string) {
  try {
    const response = await serverClient.get<ITechnology>(`/Tecnologia/${id}`)

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * PrecoTecnologia -  TechnologyRoyalties
 * ----------------------------------------------------------------------------
 */

interface IGetTechPriceOptions {
  page?: number
  pageSize?: number
  tecnologiaId?: string
  safraId?: string
  orderBy?: string
}

export async function tryGetTechnologyRoyalties(
  options: IGetTechPriceOptions = {}
) {
  try {
    const params = {
      ...options
    }

    type Response = IPaginatedResponse<ITechPrice>
    const response = await serverClient.get<Response>(`/PrecoTecnologia/`, {
      params
    })

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function fetchTechnologyRoyaltiesByCultive(
  cultive: string,
  harvest: string,
  referenceDate: Date,
  hasPriceToThisHarvest?: boolean
) {
  try {
    const formatedDate = moment(referenceDate).format('YYYY-MM-DD')
    const params = { checarPossuiPrecoSafra: hasPriceToThisHarvest }

    const endpoint = `/PrecoTecnologia/${cultive}/${harvest}/${formatedDate}`
    const response = await serverClient.get<ITechPrice>(endpoint, {
      params
    })

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryPutTechPrice(data: ITechPricePayload) {
  try {
    await serverClient.put('/PrecoTecnologia/TecnologiaSafra', data)
  } catch (e) {
    throw createApiError(e)
  }
}

export async function fetchHasRoyaltyPriceToThisHarvest(harvestId: string) {
  try {
    const response = await serverClient.get<boolean>(
      `/PrecoTecnologia/${harvestId}/possuiPreco`
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * Cultiraves
 * ----------------------------------------------------------------------------
 */

export interface IGetCultivesOptions {
  page?: number
  pageSize?: number
  obtentoraId?: string
  nome?: string
  orderBy?: string
  safraId?: string
  cultivarId?: string
  SafraIdComVolume?: string
  ComPrecoParaSafraId?: string
  TipoCategoriaCultivar?: number
  include?: string[]
}

export async function tryGetCultives(options: IGetCultivesOptions = {}) {
  try {
    const params = {
      page: 1,
      pageSize: 999,
      ...options
    }

    const response = await serverClient.get<IPaginatedResponse<ICultive>>(
      '/Cultivar',
      { params }
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetCultive(id: string, include?: string[]) {
  try {
    const response = await serverClient.get<ICultive>(`/Cultivar/${id}`, {
      params: { include }
    })

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetCultiveWithVolume(
  safraId: string,
  cultivarId: string
) {
  try {
    const url = `/Cultivar/volumeDisponivel/${safraId}/${cultivarId}`

    const response = await serverClient.get<ICultiveWithVolume>(url)

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetCultiveWithVolumeRegional(
  safraId: string,
  cultivarId: string,
  regionalId?: string
) {
  try {
    const params = { safraId, cultivarId, regionalId }
    const url = `/CotaRegional/VolumeDisponivel`

    const response = await serverClient.get<ICultiveWithVolume>(url, { params })

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * Recomendation Rate - IndiceRecomendação
 * ----------------------------------------------------------------------------
 */

export async function getRecomendationRate(
  cultiveId: string,
  harvestId: string
) {
  try {
    const response = await serverClient.get<IRecomendationRate>(
      `/IndiceRecomendacao/${cultiveId}/${harvestId}`
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function saveRecomendationRate(data: IRecomendationRateData) {
  try {
    if (!data.id)
      await serverClient.post<IRecomendationRate>('/IndiceRecomendacao/', data)
    else
      await serverClient.put<IRecomendationRate>(
        `/IndiceRecomendacao/${data.id}`,
        data
      )
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * Germoplasma - PrecoGermoplasma
 * ----------------------------------------------------------------------------
 */

export async function tryGetGermoPrice(
  cultiveId = '',
  harvestId = '',
  regionId = NaN
) {
  try {
    const response = await serverClient.get(
      `/PrecoGermoplasma/${cultiveId}/${harvestId}/${regionId}`
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function fetchSeedPriceByReferenceDate(
  cultive: string,
  harvest: string,
  region: number,
  referenceDate: Date
) {
  try {
    const params = {
      DataReferenciaCorrecao: moment(referenceDate).format('YYYY-MM-DD')
    }

    const endpoint = `/PrecoGermoplasma/${cultive}/${harvest}/${region}`
    const response = await serverClient.get<IGermoPrice>(endpoint, { params })

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryPutGermoPrice(data: IGermoPriceFormData) {
  try {
    if (!data.id) await serverClient.post('/PrecoGermoplasma/', data)
    else await serverClient.put(`/PrecoGermoplasma/${data.id}`, data)
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * PMS - PesoMilSemente
 * ----------------------------------------------------------------------------
 */

export async function tryGetPms(cultiveId = '', harvestId = '') {
  try {
    const response = await serverClient.get(
      `/PesoMilSemente/${cultiveId}/${harvestId}`
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryPutPms(data: IPmsFormData) {
  try {
    if (!data.id) await serverClient.post('/PesoMilSemente/', data)
    else await serverClient.put(`/PesoMilSemente/${data.id}`, data)
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * Pop - PopulacaoRegiao
 * ----------------------------------------------------------------------------
 */

export async function tryGetPopulation(
  cultiveId = '',
  harvestId = '',
  regionTypeId?: string
) {
  try {
    const response = await serverClient.get(
      `/PopulacaoRegiao/${cultiveId}/${harvestId}/${regionTypeId || ''}`
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function fetchPopulationByFarm(
  cultive: string,
  harvest: string,
  farm: number
) {
  try {
    const endpoint = `/PopulacaoRegiao/${cultive}/${harvest}/fazenda/${farm}`
    const response = await serverClient.get<IPopulation>(endpoint)

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryPutPopulation(data: IPopulationFormData) {
  try {
    if (!data.id) await serverClient.post('/PopulacaoRegiao/', data)
    else await serverClient.put(`/PopulacaoRegiao/${data.id}`, data)
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * Frete - PrecoFrete
 * ----------------------------------------------------------------------------
 */

export async function fetchShippingPriceByFarm(harvest: string, farm: number) {
  try {
    const endpoint = `/PrecoFrete/fazenda/${farm}/${harvest}`
    const response = await serverClient.get<IFreightPrice>(endpoint)

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetFreightPrice(regionId = '', harvestId = '') {
  try {
    const response = await serverClient.get(
      `/PrecoFrete/${regionId}/${harvestId}`
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryPutFreightPrice(data: IFreightPriceFormData) {
  try {
    if (!data.id) await serverClient.post('/PrecoFrete/', data)
    else await serverClient.put(`/PrecoFrete/${data.id}`, data)
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * Precos - DescontoPermitido
 * ----------------------------------------------------------------------------
 */

export async function tryGetMaxDiscount(harvestId = '') {
  try {
    const response = await serverClient.get(
      `/DescontoPermitido/safra/${harvestId}`
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryPutMaxDiscount(data: IMaxDiscountFormData) {
  try {
    if (!data.id) await serverClient.post('/DescontoPermitido/', data)
    else await serverClient.put(`/DescontoPermitido/${data.id}`, data)
  } catch (e) {
    throw createApiError(e)
  }
}

export async function fetchProfessionals(include?: string[]) {
  try {
    const endpoint = '/pedido/profissionaisAtribuiveis'
    const { data } = await serverClient.get<any[]>(endpoint, {
      params: {
        include
      }
    })

    return plainToClass(Professional, data)
  } catch (e) {
    throw createApiError(e)
  }
}

export async function fetchProfessional(id: number, include?: string[]) {
  try {
    const endpoint = `/Profissional/${id}`
    const { data } = await serverClient.get(endpoint, {
      params: {
        include
      }
    })

    return plainToClass(Professional, data)
  } catch (e) {
    throw createApiError(e)
  }
}
/**
 * ----------------------------------------------------------------------------
 * RTV - RepresentanteVenda
 * ----------------------------------------------------------------------------
 */

export async function tryGetRTV(regionId = '') {
  try {
    const config = {
      params: {
        regiaoId: regionId,
        include: ['Profissional']
      }
    }

    const response = await serverClient.get('/RepresentanteVenda', config)

    return response.data.result
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * Quota - Cota
 * ----------------------------------------------------------------------------
 */

export async function tryGetQuota(
  harvestId: string,
  cultiveId: string,
  professionalId: string,
  directSale: boolean
) {
  try {
    const config = {
      params: {
        safraId: harvestId,
        cultivarId: cultiveId,
        representanteVendaId: professionalId,
        isVendaDireta: directSale
      }
    }

    const response = await serverClient.get<IPaginatedResponse<IQuota>>(
      '/Cota',
      config
    )

    return response.data.result
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetQuotaVendaDireta(
  harvestId: string,
  cultiveId: string
) {
  try {
    const config = {
      params: {
        safraId: harvestId,
        cultivarId: cultiveId,
        isVendaDireta: true
      }
    }

    const response = await serverClient.get<IPaginatedResponse<IQuota>>(
      '/CotaRegional',
      config
    )

    return response.data.result
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryPutQuota(data: IQuotaFormData) {
  try {
    if (!data.id) await serverClient.post('/Cota', data)
    else await serverClient.put(`/Cota/${data.id}`, data)
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryPutRegionQuota(data: IQuotaRegionalFormData) {
  try {
    if (!!data.idVendaDireta && data.isVendaDireta && !data.regionalId) {
      await serverClient.put(`/CotaRegional/${data.id}`, { ...data })
    } else if (!data.idVendaDireta && data.isVendaDireta && !data.regionalId) {
      await serverClient.post(`/CotaRegional`, { ...data })
    } else if (!data.id) await serverClient.post('/CotaRegional', data)
    else await serverClient.put(`/CotaRegional/${data.id}`, data)
  } catch (e) {
    throw createApiError(e)
  }
}

export async function fetchAvaliableSaleVolume(
  cultive: string,
  harvest: string,
  professionalId?: number
) {
  try {
    const endpoint = `/Profissional/volumeDisponivel/${cultive}/${harvest}?profissionalId=${
      professionalId ? professionalId : ''
    }`

    const response = await serverClient.get(endpoint)

    return plainToClass(AvailableSaleVolume, response.data)
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetQuotaHistory(
  page: number,
  pageSize: number,
  cultivarId: string,
  safraId: string,
  directSale: boolean,
  include: string,
  orderBy: string,
  representanteVendaId?: string
) {
  try {
    const config = {
      params: {
        page,
        pageSize,
        cultivarId,
        isVendaDireta: directSale,
        representanteVendaId,
        ExibirApenasTipoVolumeAtual: false,
        safraId,
        include,
        orderBy
      }
    }

    const response = await serverClient.get<IPaginatedResponse<IQuotaHistory>>(
      '/Cota/cotaHistorico',
      config
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetRegionalQuotaHistory(
  page: number,
  pageSize: number,
  cultivarId: string,
  safraId: string,
  include: string,
  orderBy: string,
  regionalId?: string
) {
  try {
    const config = {
      params: {
        page,
        pageSize,
        cultivarId,
        regionalId,
        safraId,
        include,
        orderBy
      }
    }

    const response = await serverClient.get<IPaginatedResponse<IQuotaHistory>>(
      '/CotaRegional/cotaRegionalHistorico',
      config
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

// /CotaRegional/cotaRegionalHistorico?SafraId=1412941784012292096&CultivarId=578745688755503104&RegionalId=2&include=Profissional

/**
 * ----------------------------------------------------------------------------
 * Agent - Agente
 * ----------------------------------------------------------------------------
 */

export interface IGetAgentsOptions {
  nome?: string
  page?: number
  pageSize?: number
  orderBy?: string
  todosAtivosEInativosComVolumeSafra?: string
}

export async function tryGetAgents(options?: IGetAgentsOptions) {
  try {
    const params = {
      include: ['Profissional'],
      ...options
    }

    const response = await serverClient.get<IPaginatedResponse<IAgent>>(
      '/Agente/rtv',
      { params }
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetAgent(id: string) {
  try {
    const response = await serverClient.get<IAgent>(`/Agente/${id}`)

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * Forecast - Forecast RTV/Varredura
 * ----------------------------------------------------------------------------
 */

export interface IGetVarreduraOptions {
  clienteId?: number
  safraId?: string
  cultivarId?: string
  varreduraPeriodoId?: number
  page?: number
  pageSize?: number
  orderBy?: string
}

export async function tryGetVarreduras(options: IGetVarreduraOptions) {
  try {
    const params = {
      ...options
    }

    const response = await serverClient.get<IPaginatedResponse<IForecast>>(
      '/Varredura',
      { params }
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetVarredura(options: IGetVarreduraOptions) {
  try {
    const params = {
      ...options
    }

    const response = await serverClient.get<IPaginatedResponse<IForecast>>(
      '/Varredura',
      { params }
    )

    return response.data.result
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetVarreduraById(id: number) {
  try {
    const response = await serverClient.get<IPaginatedResponse<IForecast>>(
      `/Varredura/${id}`
    )

    return response.data.result
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryPutVarredura(data: IForecast) {
  try {
    if (!data.id) await serverClient.post('/Varredura', data)
    else await serverClient.put(`/Varredura/${data.id}`, data)
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * Customer Share
 * ----------------------------------------------------------------------------
 */

export async function tryGetCustomerShare(harvestId: string, clientId: number) {
  try {
    const response = await serverClient.post<ICustomerShare>(
      `/CustomerShare/${harvestId}/${clientId}`
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetWalletShare(
  harvestId: string,
  clientId: number,
  carteiraId: number
) {
  try {
    const response = await serverClient.post<IWalletShare>(
      `/RepresentatividadeCarteira/${harvestId}/${clientId}/${carteiraId}`
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * Imposto Barter / Custo Operacional Barter
 * ----------------------------------------------------------------------------
 */

export async function tryGetBarterTax() {
  try {
    const response = await serverClient.get<IBarterTax>(
      '/ImpostoBarter/GetCurrent'
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryPostBarterTax(valor: number) {
  try {
    const response = await serverClient.post('/ImpostoBarter', { valor })

    return response
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * RtvQuota - Cota/VolumeDisponivel/rtv
 * ----------------------------------------------------------------------------
 */

export async function tryGetRtvQuotas(
  page: number,
  pageSize: number,
  harvestId: string,
  query?: string,
  orderBy?: string
) {
  try {
    const params = {
      page,
      pageSize,
      cultivarNome: query,
      safraId: harvestId,
      orderBy
    }

    const response = await serverClient.get<IPaginatedResponse<IRtvQuota>>(
      `/Cota/VolumeDisponivel/rtv?VolumeExistente=true`,
      { params }
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetRtvQuota(cultivarId: string, safraId: string) {
  try {
    const params = {
      cultivarId,
      safraId
    }

    const response = await serverClient.get<IPaginatedResponse<IRtvQuota>>(
      `/Cota/VolumeDisponivel/rtv?VolumeExistente=true`,
      { params }
    )

    return response.data.result[0]
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * SubQuota - SubCota
 * ----------------------------------------------------------------------------
 */

export async function tryGetSubQuotas(
  page: number,
  pageSize: number,
  harvestId: string,
  orderBy?: string,
  query?: string
) {
  try {
    const params = {
      page,
      pageSize,
      safraId: harvestId,
      orderBy,
      cultivarNome: query
    }

    const response = await serverClient.get(
      '/Subcota/volumeDisponivel/logado',
      { params }
    )

    return response.data.result
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetSubQuota(
  harvestId = '',
  cultiveId = '',
  agentId = ''
) {
  try {
    const config = {
      params: {
        safraId: harvestId,
        cultivarId: cultiveId,
        agenteId: agentId
      }
    }

    const response = await serverClient.get('/Subcota', config)

    return response.data.result
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryPutSubQuota(data: ISubQuotaFormData) {
  try {
    if (!data.id) await serverClient.post('/Subcota', data)
    else await serverClient.put(`/Subcota/${data.id}`, data)
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * Período - Period
 * ----------------------------------------------------------------------------
 */

export async function tryGetPeriodos(
  page: number,
  pageSize: number,
  nome?: string,
  safraId?: string,
  startDate?: number,
  endDate?: number
) {
  const config = {
    params: {
      page,
      pageSize,
      nome,
      safraId,
      startDate,
      endDate
    }
  }

  try {
    const response = await serverClient.get<IPaginatedResponse<IPeriod>>(
      '/VarreduraPeriodo',
      config
    )
    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetPeriodoAtivo() {
  try {
    const response = await serverClient.get<IPeriod>(
      '/VarreduraPeriodo/GetPeriodoAtivo'
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetPeriodo(id: number) {
  try {
    const response = await serverClient.get<IPeriod>(`/VarreduraPeriodo/${id}`)
    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryPutPeriodo(data: IPeriodSubmitPayload) {
  try {
    if (data.id)
      await serverClient.put<IPeriod>(`/VarreduraPeriodo/${data.id}`, data)
    else await serverClient.post<IPeriod>(`/VarreduraPeriodo`, data)
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryInactivatePeriod() {
  try {
    await serverClient.put(`/VarreduraPeriodo/InativarPeriodo`)
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryDeletePeriod(id: number) {
  try {
    await serverClient.delete(`/VarreduraPeriodo/${id}`)
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * SeasonalDiscount - CampanhaDesconto
 * ----------------------------------------------------------------------------
 */

export async function tryGetSeasonalDiscounts(
  page: number,
  pageSize?: number,
  cultiveId?: string,
  harvestId?: string,
  ApenasVigentes?: boolean
) {
  try {
    const config = {
      params: {
        page,
        pageSize,
        cultivarId: cultiveId,
        safraId: harvestId,
        ApenasVigentes,
        orderBy: 'DataInicial'
      }
    }

    type Response = IPaginatedResponse<ISeasonalDiscount>
    const response = await serverClient.get<Response>(
      '/CampanhaDesconto',
      config
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryPutSeasonalDiscounts(data: ISeasonalDiscountPayload) {
  try {
    await serverClient.put('/CampanhaDesconto/CultivarSafra', data)
  } catch (e) {
    throw createApiError(e)
  }
}

export async function fetchDiscountCampaignByDate(
  cultive: string,
  harvest: string
) {
  try {
    const formatedDate = moment().format('YYYY-MM-DD')
    const endpoint = `/CampanhaDesconto/${cultive}/${harvest}/${formatedDate}`

    const response = await serverClient.get<ISeasonalDiscount>(endpoint)

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * Carteira - Wallet
 * ----------------------------------------------------------------------------
 */

export async function tryGetWallets(
  page: number,
  pageSize?: number,
  clienteId?: number,
  orderBy?: string,
  nome?: string,
  profissionalId?: number
) {
  try {
    const config = {
      params: {
        page,
        pageSize,
        clienteId,
        orderBy,
        nome,
        profissionalId,
        include: [
          'Profissional',
          'Clientes.Cliente',
          'Profissional.RepresentanteVenda',
          'Profissional.RepresentanteVenda.Agentes',
          'Profissional.Agente',
          'Profissional.Agente.RepresentanteVenda'
        ]
      }
    }

    const response = await serverClient.get<IPaginatedResponse<IWallet>>(
      '/Carteira',
      config
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetWallet(id: number) {
  try {
    const params = {
      Include: [
        'Profissional',
        'Clientes.Cliente',
        'Profissional.RepresentanteVenda',
        'Profissional.RepresentanteVenda.Agentes',
        'Profissional.Agente',
        'Profissional.Agente.RepresentanteVenda'
      ]
    }

    const response = await serverClient.get<IWallet>(`/Carteira/${id}`, {
      params
    })

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetWalletByProfessionalId(
  page: number,
  pageSize?: number,
  orderBy?: string,
  profissionalId?: string
) {
  try {
    const config = {
      params: {
        page,
        pageSize,
        orderBy,
        profissionalId,
        include: [
          'Profissional',
          'Clientes.Cliente',
          'Profissional.RepresentanteVenda',
          'Profissional.RepresentanteVenda.Agentes',
          'Profissional.Agente',
          'Profissional.Agente.RepresentanteVenda'
        ]
      }
    }

    const response = await serverClient.get<IPaginatedResponse<IWallet>>(
      '/Carteira',
      config
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function putWallet(data: IWalletSubmitPayload) {
  try {
    if (data.id) await serverClient.put<IWallet>(`/Carteira/${data.id}`, data)
    else await serverClient.post<IWallet>(`/Carteira`, data)
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryDeleteWallet(id: number) {
  try {
    await serverClient.delete(`/Carteira/${id}`)
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryRemoveClientFromWallet(id: number, clienteId: number) {
  const data = {
    clientes: [
      {
        clienteId
      }
    ]
  }
  try {
    const response = await serverClient.put(
      `/Carteira/RemoverClientes/${id}`,
      data
    )
    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryPutClientIntoWallet(id: number, clienteId: number) {
  const data = {
    clientes: [
      {
        clienteId
      }
    ]
  }
  try {
    const response = await serverClient.put(
      `/Carteira/AdicionarClientes/${id}`,
      data
    )
    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * Certificadoras
 * ----------------------------------------------------------------------------
 */

export async function tryGetCertificadoras() {
  try {
    const response = await serverClient.get<IPaginatedResponse<ICertificadora>>(
      `/Certificadora`
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetCertificadora(id: string) {
  try {
    const response = await serverClient.get<ICertificadora>(
      `/Certificadora/${id}`
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

/**
 * ----------------------------------------------------------------------------
 * Multiplicadoras
 * ----------------------------------------------------------------------------
 */

export async function tryGetMultiplicadoras() {
  try {
    const response = await serverClient.get<
      IPaginatedResponse<IMultiplicadora>
    >(`/Multiplicador`)

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}

export async function tryGetMultiplicadora(id: string) {
  try {
    const response = await serverClient.get<IMultiplicadora>(
      `/Multiplicador/${id}`
    )

    return response.data
  } catch (e) {
    throw createApiError(e)
  }
}
